.dropzone-container {
  width: 100%;
  flex: 1;
  height: 100px;
  border: 3px dashed #3498db;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;

  > div {
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;

    > p {
      margin-bottom: 0;
      font-family: Poppins;
      color: #7b8a8b;
    }
  }

  &:hover {
    border-color: #18bc9c;
    background: #ecf7ff;
  }
}
