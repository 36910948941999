.App {
  text-align     : center;
  display        : flex;
  flex-direction : column;
  align-items    : center;
  height: 100vh;
}

.App-logo {
  height        : 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height      : 100vh;
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  font-size       : calc(10px + 2vmin);
  color           : white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.profile-feed {
  width: 500px;
  display              : grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.post {
  /* box-shadow: 0 0 0 1px #000; */
  background: gray;
  display   : flex;
  cursor    : pointer;

}

.post img {
  margin : 0;
  padding: 0;
}